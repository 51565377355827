<button (click)="skipToMainContent()" class="sr-only" id="skip-to-main-content">
  {{ 'root.skip-to-content' | translate }}
</button>

<div class="outer-wrapper" [class.d-none]="shouldShowFullscreenLoader" [@slideSidebarPadding]="{
     value: (!(isSidebarVisible$ | async) ? 'hidden' : (slideSidebarOver$ | async) ? 'unpinned' : 'pinned'),
     params: { collapsedWidth: (collapsedSidebarWidth$ | async), expandedWidth: (expandedSidebarWidth$ | async) }
    }">
  <ds-themed-admin-sidebar [expandedSidebarWidth$]="expandedSidebarWidth$" [collapsedSidebarWidth$]="collapsedSidebarWidth$"></ds-themed-admin-sidebar>
  <div class="inner-wrapper">
    <ds-system-wide-alert-banner></ds-system-wide-alert-banner>
    <ds-themed-header-navbar-wrapper></ds-themed-header-navbar-wrapper>
    <ds-themed-breadcrumbs></ds-themed-breadcrumbs>
    <main class="my-cs" id="main-content">

      <div class="container d-flex justify-content-center align-items-center h-100" *ngIf="shouldShowRouteLoader">
        <ds-themed-loading [showMessage]="false"></ds-themed-loading>
      </div>
      <div [class.d-none]="shouldShowRouteLoader">
        <router-outlet></router-outlet>
      </div>
    </main>

    <ds-themed-footer></ds-themed-footer>
  </div>
</div>

<ds-notifications-board [options]="notificationOptions">
</ds-notifications-board>

<div class="ds-full-screen-loader" *ngIf="shouldShowFullscreenLoader">
  <ds-themed-loading [showMessage]="false"></ds-themed-loading>
</div>

<ds-live-region></ds-live-region>
