<div class="col-md-12">
  <div class="row grid-item-container justify-content-center">
    <div *ngIf="showThumbnails" class="col-md-2 px-0 d-flex flex-column justify-content-center">
      <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener noreferrer' : null"
         [routerLink]="[itemPageRoute]" class="dont-break-out">
        <ds-thumbnail [thumbnail]="dso?.thumbnail | async" [limitWidth]="true">
        </ds-thumbnail>
      </a>
      <span *ngIf="linkType == linkTypes.None" class="dont-break-out">
    <ds-thumbnail [thumbnail]="dso?.thumbnail | async" [limitWidth]="true">
    </ds-thumbnail>
    </span>
    </div>
    <div [ngClass]="showThumbnails ? 'col-md-10' : 'col-md-12'" class="py-2">
      <ds-truncatable [id]="dso.id" *ngIf="object !== undefined && object !== null">
        <div class="d-flex flex-column justify-content-between h-100">
          <ds-truncatable-part [id]="dso.id" [minLines]="2">
            <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
               [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener noreferrer' : null"
               [routerLink]="[itemPageRoute]" class="lead item-list-title dont-break-out"
               [innerHTML]="dsoTitle"></a>
            <span *ngIf="linkType == linkTypes.None" class="lead item-list-title dont-break-out"
                  [innerHTML]="dsoTitle"></span>
          </ds-truncatable-part>
          <div class="text-muted authors-list">
            <ds-truncatable-part [id]="dso.id" [minLines]="1">
          <span *ngIf="dso.allMetadata(['dc.creator']).length > 0" class="item-list-authors">
            <span *ngFor="let author of allMetadataValues(['dc.creator']); let last=last;">
              <a routerLink="/search" [queryParams]="generateSearchParams(author, 'author')">
                <span [innerHTML]="author"><span [innerHTML]="author"></span></span>
              </a>
              <span *ngIf="!last">; </span>
            </span>
          </span>
            </ds-truncatable-part>
          </div>
          <hr class="my-1 mx-0">
          <div *ngIf="dso.firstMetadataValue('dc.description.abstract')"
               class="item-list-abstract unicode-bidi-text justify-text">
            <ds-truncatable-part [id]="dso.id" [minLines]="3">
              <span [innerHTML]="firstMetadataValue('dc.description.abstract')"></span>
            </ds-truncatable-part>
          </div>
          <hr class="my-1 mx-0">
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <div *ngIf="dso.firstMetadataValue('dc.type')" class="text-muted">
              <i *ngIf="dso.firstMetadataValue('cg.identifier.status') === 'Open access'"
                 [title]="dso.firstMetadataValue('cg.identifier.status')"
                 [ngbTooltip]="dso.firstMetadataValue('cg.identifier.status')"
                 class="fa-solid fa-lock-open pr-2"></i>
              <i *ngIf="dso.firstMetadataValue('cg.identifier.status') !== 'Open access'"
                 [title]="dso.firstMetadataValue('cg.identifier.status')"
                 [ngbTooltip]="dso.firstMetadataValue('cg.identifier.status')"
                 class="fa-solid fa-lock pr-2"></i>
              <a routerLink="/search" [queryParams]="generateSearchParams(firstMetadataValue('dc.type'), 'itemtype')">
                <span class="item-list-date" [innerHTML]="firstMetadataValue('dc.type')"></span>
              </a>
            </div>
            <ds-item-page-statistics [item]="dso"
                                     [labelViews]="'item.page.statistics.views'"
                                     [labelDownloads]="'item.page.statistics.downloads'"
                                     [widget]="false">
            </ds-item-page-statistics>
            <div *ngIf="dso.firstMetadataValue('dc.date.issued')" class="text-muted">
              <span class="item-list-date" [innerHTML]="firstMetadataValue('dc.date.issued')"></span>
            </div>
          </div>
        </div>
      </ds-truncatable>
    </div>
  </div>
</div>
