<div class="card" [@focusShadow]="(isCollapsed$ | async)?'blur':'focus'">
  <div class="position-absolute ml-1">
    <ng-content></ng-content>
  </div>
  <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
     [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener noreferrer' : null" [routerLink]="[itemPageRoute]"
     class="card-img-top full-width" [attr.title]="'search.results.view-result' | translate">
    <div>
      <ds-themed-thumbnail [externalThumbnail]="dso.firstMetadataValue('mel.file.thumbnail')"
                           [thumbnail]="dso?.thumbnail | async" [limitWidth]="false">
      </ds-themed-thumbnail>
    </div>
  </a>
  <span *ngIf="linkType == linkTypes.None" class="card-img-top full-width">
    <div>
      <ds-themed-thumbnail [externalThumbnail]="dso.firstMetadataValue('mel.file.thumbnail')"
                           [thumbnail]="dso?.thumbnail | async" [limitWidth]="false">
      </ds-themed-thumbnail>
    </div>
    </span>
  <div class="card-body">
    <ds-truncatable [id]="dso.id">
      <ds-truncatable-part [id]="dso.id" [minLines]="2" type="h4">
        <h4 class="card-title" [innerHTML]="dsoTitle"></h4>
      </ds-truncatable-part>
      <ds-truncatable-part [id]="dso.id" [minLines]="1">
        <span *ngIf="dso.allMetadata(['dc.creator']).length > 0" class="item-list-authors">
          <span *ngFor="let author of allMetadataValues(['dc.creator']); let last=last;">
            <a routerLink="/search" [queryParams]="generateSearchParams(author, 'author')">
              <span [innerHTML]="author"><span [innerHTML]="author"></span></span>
            </a>
            <span *ngIf="!last">; </span>
          </span>
        </span>
      </ds-truncatable-part>
      <hr class="my-1 mx-0">
      <div *ngIf="dso.firstMetadataValue('dc.description.abstract')"
           class="item-list-abstract unicode-bidi-text justify-text">
        <ds-truncatable-part [id]="dso.id" [minLines]="3">
          <span [innerHTML]="firstMetadataValue('dc.description.abstract')"></span>
        </ds-truncatable-part>
      </div>
      <hr class="my-1 mx-0">
      <div class="d-flex flex-wrap justify-content-between align-items-center my-2">
        <div *ngIf="dso.firstMetadataValue('dc.type')" class="text-muted">
          <i *ngIf="dso.firstMetadataValue('cg.identifier.status') === 'Open access'"
             [title]="dso.firstMetadataValue('cg.identifier.status')"
             [ngbTooltip]="dso.firstMetadataValue('cg.identifier.status')"
             class="fa-solid fa-lock-open pr-2"></i>
          <i *ngIf="dso.firstMetadataValue('cg.identifier.status') !== 'Open access'"
             [title]="dso.firstMetadataValue('cg.identifier.status')"
             [ngbTooltip]="dso.firstMetadataValue('cg.identifier.status')"
             class="fa-solid fa-lock pr-2"></i>
          <a routerLink="/search" [queryParams]="generateSearchParams(firstMetadataValue('dc.type'), 'itemtype')">
            <span class="item-list-date" [innerHTML]="firstMetadataValue('dc.type')"></span>
          </a>
        </div>
        <div *ngIf="dso.firstMetadataValue('dc.date.issued')" class="text-muted">
          <span class="item-list-date" [innerHTML]="firstMetadataValue('dc.date.issued')"></span>
        </div>
      </div>
      <hr class="mb-2 mt-0 mx-0">
    </ds-truncatable>
    <div *ngIf="linkType != linkTypes.None">
      <a [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
         [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener noreferrer' : null" [routerLink]="[itemPageRoute]"
         class="lead btn btn-primary viewButton w-100">{{ 'search.results.view-result' | translate }}</a>
    </div>
  </div>
  <ng-content></ng-content>
</div>
