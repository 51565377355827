<a *ngIf="!item.disabled"
   class="ds-menu-item" role="menuitem"
   routerLinkActive="active"
   href="javascript:void(0);"
   [title]="item.text | translate"
   (click)="activate($event)"
   (keyup.space)="activate($event)"
   (keyup.enter)="activate($event)"
   [attr.data-test]="item.text"
>{{item.text | translate}}</a>
<span *ngIf="item.disabled" [attr.data-test]="item.text" class="nav-item nav-link disabled">{{item.text | translate}}</span>
